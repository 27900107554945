import Swiper from 'swiper/bundle';
import 'jquery-AniView/dist/jquery.aniview';
export default {
  init() {
    // JavaScript to be fired on all pages
    $('.bar-menu').click(function(e) {
      e.preventDefault();
      $('#main-menu').toggleClass('menu-active');
      $('.right-side .ubermenu-submenu').matchHeight();
      $('.right-side .ubermenu-target').matchHeight();
    });

    var options = {
      animateClass: 'animate__animated',
      animateThreshold: 50,
      scrollPollInterval: 20,
    }
    $('.aniview').AniView(options);
    
    new Swiper('.services-block', {
      slidesPerView: 2,
      slidesPerColumn: 2,
      slidesPerGroup: 2,
      spaceBetween: 0,
      autoplay: false,
      allowTouchMove: false,
      allowSlideNext: false,
      allowSlidePrev: false,
      navigation: {
        nextEl: '#services-next',
        prevEl: '#services-back',
      },
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 2,
          spaceBetween: 0,
          slidesPerColumn: 2,
          slidesPerGroup: 2,
        },
        // when window width is >= 640px
        1080: {
          slidesPerView: 2,
          spaceBetween: 0,
          slidesPerColumn: 2,
          slidesPerGroup: 2,
        },
      },
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
